//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name:'RFTIC-footer',
  data() {
    return {
      socialList: [
        {
          icon: "fa-facebook-f",
          url: "https://www.facebook.com/rfticegypt/",
        },
      ],
    };
  },
};
