//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Header from '@/modules/partner/components/RFTIC/header.vue';
import Footer from '@/modules/partner/components/RFTIC/footer.vue';
import Title from '@/modules/partner/components/RFTIC/title.vue';
// import Products from '@/modules/partner/components/RFTIC/products.vue';
// import Branches from '@/modules/partner/components/RFTIC/branches.vue';
// import Contact from '@/modules/partner/components/RFTIC/contact.vue';
import i18n from '@/vueI18n';

export default {
  components: {
    [Header.name]: Header,
    [Footer.name]: Footer,
    [Title.name]: Title,
    // [Products.name]: Products,
    // [Branches.name]: Branches,
    // [Contact.name]: Contact,
  },
  computed: {
    language() {
      return i18n.locale;
    },
  },
  watch: {
    language() {
      document.title = this.i18n('app.rftic');
    },
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
  },
};
